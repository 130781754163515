import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import BigHighlight from "../BigHighlight";

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      className: "slider"
    };

    return (
      <Slider {...settings}>
        {this.props.items.map((value, index) => (
          <BigHighlight
            title={value.title}
            description={value.description}
            link={value.link}
            button={value.button}
            image={require(`../../images/${value.image}`)}
            external={value.external}
            key={`carrousel - ${index}`}
          />
        ))}
      </Slider>
    );
  }
}

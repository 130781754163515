import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert2-react';

import API from '../../api';

export default function Form() {
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    icon: '',
    title: '',
    show: false
  });

  const onSubmit = values => {
    setLoading(true);

    API.post(`backend/sendmail`, { values })
      .then(res => {
        setLoading(false);
        if (res.data.success) {
          setMessage({
            icon: 'success',
            title: t('contact.form.feedback.success'),
            show: true
          });
        } else {
          setMessage({
            icon: 'error',
            title: t('contact.form.feedback.error'),
            show: true
          });
        }
      });
  }

  return (
    <div className="wrapper-form">
      {
        loading && (<div className="loading"></div>)
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name" className={(errors.name) ? 'error' : ''}>
          <input
            id="name"
            name="name"
            placeholder={t('contact.form.labels.name')}
            ref={register({ required: true })}
          />
        </label>
        <label htmlFor="email" className={(errors.email) ? 'error' : ''}>
          <input
            id="email"
            name="email"
            placeholder={t('contact.form.labels.email')}
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: `${t('contact.form.invalid')}`
              }
            })}
          />
        </label>
        <label htmlFor="phone" className={(errors.phone) ? 'error' : ''}>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder={t('contact.form.labels.phone')}
            ref={register({ required: true })}
            maxLength="25"
          />
        </label>
        <label htmlFor="type" className={(errors.type) ? 'wrapper-select error' : 'wrapper-select'}>
          <select id="type" name="type" ref={register({ required: true })}>
            <option value="">{t('contact.form.labels.type.label')}</option>
            <option value={t('contact.form.labels.type.compliment')}>{t('contact.form.labels.type.compliment')}</option>
            <option value={t('contact.form.labels.type.suggestion')}>{t('contact.form.labels.type.suggestion')}</option>
            <option value={t('contact.form.labels.type.complaint')}>{t('contact.form.labels.type.complaint')}</option>
            <option value={t('contact.form.labels.type.information')}>{t('contact.form.labels.type.information')}</option>
          </select>
        </label>
        <label htmlFor="message" className={(errors.message) ? 'error' : ''}>
          <textarea
            id="message"
            name="message"
            placeholder={t('contact.form.labels.message')}
            ref={register({ required: true })}></textarea>
        </label>
        <button type="submit">{t('contact.form.labels.submit')}</button>
      </form>
      <p>{t('contact.form.required')}</p>
      {message.show && (
        <SweetAlert
          type={message.icon}
          show={message.show}
          title={message.title}
          onConfirm={() => setMessage({
            icon: '',
            title: '',
            show: false
          })}
        />
      )}
    </div>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PageIntro extends Component {
  render() {
    return (
      <div className="page-intro">
        <div className="wrapper-main wrapper-content">
          <h2>{this.props.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
        </div>
      </div>
    );
  }
}

PageIntro.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

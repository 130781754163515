import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import Item from './item';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export default class Timeline extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="wrapper-timeline" >
        <div className="wrapper-main">
          <Slider {...settings}>
            {this.props.timeline.map((value, index) => (
              <Item image={require(`../../images/${value.image}`)} year={value.year} description={value.description} classtype={value.classtype} key={`time-${index}`} />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

Timeline.propTypes = {
  timeline: PropTypes.array
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Place from './place';

export default class Address extends Component {
  render() {
    return (
      <div className="wrapper-address">
        <img src={require('../../images/img-contact.jpg')} alt="Fábrica - Serafina Corrêa / RS" />
        {this.props.places.map((value, index) => (
          <Place name={value.name} address={value.address} key={`places-${index}`} />
        ))}
      </div>
    );
  }
}

Address.propTypes = {
  places: PropTypes.array
}
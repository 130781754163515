import React, { Component } from 'react';
import i18n from 'i18next';

import PageHeader from '../../components/PageHeader';
import PageIntro from '../../components/PageIntro';

import image from '../../images/img-high-governance.jpg';

export default class Governance extends Component {
  render() {
    return (
      <>
        <PageHeader image={image} />
        <PageIntro title={i18n.t('governance.title')} description={i18n.t('governance.description')} />
      </>
    );
  }
}

import React, { Component } from 'react';
import i18n from "i18next";

import PageHeader from '../../components/PageHeader';
import PageIntro from '../../components/PageIntro';

import bgHighlight from '../../images/img-high-people.jpg';

export default class People extends Component {
    render() {
        return (
            <>
                <PageHeader image={bgHighlight} />
                <PageIntro title={i18n.t('people.title')} description={i18n.t('people.description')} />
            </>
        );
    }
}

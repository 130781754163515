import React, { Component } from 'react';
import i18n from "i18next";

import PageHeader from '../../components/PageHeader';
import PageIntro from '../../components/PageIntro';
import Form from '../../components/Form';
import Address from '../../components/Address';

import image from '../../images/img-high-contact.jpg';

export default class Contact extends Component {
  render() {
    return (
      <>
        <PageHeader image={image} />
        <PageIntro title={i18n.t('contact.title')} />
        <div className="wrapper-contact">
          <div className="wrapper-main">
            <Form />
            <Address places={i18n.t('contact.places', { returnObjects: true })} />
          </div>
        </div>
      </>
    );
  }
}

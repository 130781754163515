import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Value extends Component {
  render() {
    return (
      <div className="item-values">
        <i><img src={this.props.icon} alt={this.props.title} /></i>
        <h4>{this.props.title}</h4>
        <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
      </div>
    );
  }
}

Option.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

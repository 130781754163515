import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Navigation from '../../components/Navigation';
import Language from '../../components/Language';
import logo from '../../images/logo.png';

export default function Header() {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);
    const [menu, setMenu] = useState(false);

    function openMenu() {
        setMenu(!menu);
    }

    function changeLang(lang) {
        setLang(lang);
    }

    return (
        <header id="header">
            <div className="wrapper-main wrapper-header">
                <div className="header-line">
                    <Link to={lang === 'en' ? `/` : `/${lang}`} className="logo"><img src={logo} alt="Logo" /></Link>
                    <p dangerouslySetInnerHTML={{ __html: i18n.t('header.slogan') }} />
                    <Language changeLang={changeLang} />
                    <button className="btn-menu" onClick={() => openMenu()}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <Navigation lang={lang} isOpen={menu} callback={() => openMenu()} changeLang={changeLang} />
            </div>
        </header>
    );
}
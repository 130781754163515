import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function Language(props) {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [lang, setLang] = useState(i18n.language);

  function changeLanguage(lang) {
    const lng = (lang !== 'en') ? lang : '';
    i18n.changeLanguage(lang).then(() => history.push(`/${lng}`));
    setLang(lang, i18n.language);
    props.changeLang(i18n.language);
  }

  return (
    <ul className="lang">
      <li><button onClick={() => changeLanguage('br')} className={lang === 'br' ? 'active' : ''}>PT</button></li>
      <li><button onClick={() => changeLanguage('en')} className={lang === 'en' ? 'active' : ''}>EN</button></li>
    </ul>
  );
}

Language.propTypes = {
  changeLang: PropTypes.func.isRequired
}
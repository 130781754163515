import React, { Component } from 'react';
import i18n from "i18next";

import PageHeader from '../../components/PageHeader';
import PageIntro from '../../components/PageIntro';
import Timeline from '../../components/Timeline';

import image from '../../images/img-high-grano.png';

export default class About extends Component {
  render() {
    return (
      <>
        <PageHeader image={image} />
        <PageIntro title={i18n.t('about.title')} description={i18n.t('about.description')} />
        <Timeline timeline={i18n.t('about.timeline', { returnObjects: true })} />
      </>
    );
  }
}

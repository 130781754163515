import React, { Component } from 'react';
import i18n from "i18next";

import PageHeader from '../../components/PageHeader';
import PageIntro from '../../components/PageIntro';

import image from '../../images/img-high-sustentability.jpg'

export default class Sustainability extends Component {
  render() {
    return (
      <>
        <PageHeader image={image} />
        <PageIntro title={i18n.t('sustainability.title')} description={i18n.t('sustainability.description')} />
      </>
    )
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PageIntro from '../PageIntro';
import Value from './value';

export default class ListValues extends Component {
  render() {
    return (
      <div className="wrapper-values">
        <div className="wrapper-main">
          <PageIntro title={this.props.title} />
          <div className="listValues">
            {this.props.list.map((value, index) => (
              <Value icon={require(`../../images/${value.icon}`)} title={value.title} description={value.description} key={`value-${index}`} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

ListValues.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Option from './option';

export default class ListOptions extends Component {
  render() {
    return (
      <div className="list-options">
        {this.props.options.map((option, index) => (
          <Option
            icon={require(`../../images/${option.icon}`)}
            title={option.title}
            key={`option-${index}`}
          />
        ))}
      </div>
    );
  }
}

ListOptions.propTypes = {
  options: PropTypes.array
}
import React from 'react';
import PropTypes from 'prop-types';

export default function Phone(props) {
    return (
        <a href="tel:08000420346">{props.sac}: 0800-042-0346</a>
    );
}

Phone.propTypes = {
    sac: PropTypes.string.isRequired
}
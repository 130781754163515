import React, { Component } from 'react';
import i18n from "i18next";

import PageHeader from '../../components/PageHeader';
import PageIntro from '../../components/PageIntro';
import ListBusiness from '../../components/ListBusiness';

import image from '../../images/img-high-business.jpg';

export default class Business extends Component {
  render() {
    return (
      <>
        <PageHeader image={image} />
        <PageIntro title={i18n.t('businesses.title')} description={i18n.t('businesses.description')} />
        <ListBusiness items={i18n.t('businesses.items', { returnObjects: true })} />
      </>
    );
  }
}
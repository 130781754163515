import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ItemTimeline extends Component {
  render() {
    return (
      <div className="item-timeline">
        <img src={this.props.image} alt={`Grano ${this.props.year}`} />
        <span className={this.props.classtype}></span>
        <h4>{this.props.year}</h4>
        <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
      </div>
    );
  }
}

ItemTimeline.propTypes = {
  image: PropTypes.string.isRequired,
  classtype: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}
import React from "react";
import { useTranslation } from "react-i18next";

import Phone from '../../components/Phone';
import Copyright from "../../components/Copyright";

import logo from "../../images/logo.png";
import logo_brgs from "../../images/logo-brgs.png";
import logo_eu_reciclo from "../../images/logo-eu-reciclo.png";

export default function Footer() {
  const i18n = useTranslation();

  return (
    <footer id="footer">
      <div className="wrapper-main logos">
        <h5>Certifications</h5>
        <img src={logo_brgs} alt="BRGS" />
        <img src={logo_eu_reciclo} alt="Eu reciclo" />
      </div>

      <div className="wrapper-main">
        <img src={logo} alt="Grano Alimentos" />
        <ul>
          <li>
            <a
              href="mailto:grano@grano.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              grano@grano.com.br
            </a>
          </li>
          <li>
            <span>|</span>
          </li>
          <li>
            <Phone sac={i18n.t('footer.sac')} />
          </li>
        </ul>
        <Copyright copy={i18n.t('footer.copyright')} />
      </div>
    </footer>);
}


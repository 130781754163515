import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Language from '../Language';

export default function Navigation(props) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(props.isOpen);

    function openCloseMenu() {
        setIsOpen(!isOpen);
        props.callback();
    }

    return (
        <nav className={(props.isOpen) ? 'navigation open' : 'navigation'}>
            <ul>
                <li>
                    <NavLink exact to={props.lang === 'en' ? `` : `/${props.lang}`} activeClassName="active" onClick={() => openCloseMenu()}>{t('header.navigation.home')}</NavLink>
                </li>
                <li><span></span></li>
                <li>
                    <NavLink to={props.lang === 'en' ? `/grano` : `/${props.lang}/a-grano`} activeClassName="active" onClick={() => openCloseMenu()}>{t('header.navigation.grano')}</NavLink>
                </li>
                <li><span></span></li>
                <li>
                    <NavLink to={props.lang === 'en' ? `/our-people` : `/${props.lang}/pessoas`} activeClassName="active" onClick={() => openCloseMenu()}>{t('header.navigation.people')}</NavLink>
                </li>
                <li><span></span></li>
                <li>
                    <NavLink to={props.lang === 'en' ? `/sustainability` : `/${props.lang}/sustentabilidade`} activeClassName="active" onClick={() => openCloseMenu()}>{t('header.navigation.sustainability')}</NavLink>
                </li>
                <li><span></span></li>
                <li>
                    <Link to={{ pathname: "/", hash: "#products" }}>{t('header.navigation.products')}</Link>
                </li>
                <li><span></span></li>
                <li>
                    <NavLink to={props.lang === 'en' ? `/governance` : `/${props.lang}/governanca`} activeClassName="active" onClick={() => openCloseMenu()}>{t('header.navigation.governance')}</NavLink>
                </li>
                <li><span></span></li>
                <li>
                    <NavLink to={props.lang === 'en' ? `/businesses` : `/${props.lang}/negocios`} activeClassName="active" onClick={() => openCloseMenu()}>{t('header.navigation.businesses')}</NavLink>
                </li>
                <li><span></span></li>
                <li>
                    <NavLink to={props.lang === 'en' ? `/contact` : `/${props.lang}/contato`} activeClassName="active" onClick={() => openCloseMenu()}>{t('header.navigation.contact')}</NavLink>
                </li>
                <li>
                    <Language changeLang={props.changeLang} />
                </li>
            </ul>
        </nav>
    );
}

Navigation.propTypes = {
    lang: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

export default class ListBusiness extends Component {
  render() {
    return (
      <div className="list-business">
        {this.props.items.map((item, index) => (
          <Item
            image={require(`../../images/${item.image}`)}
            title={item.title}
            description={item.description}
            key={`item-${index}`}
          />
        ))}
      </div>
    );
  }
}

ListBusiness.propTypes = {
  items: PropTypes.array
}

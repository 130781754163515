import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Text extends Component {
  render() {
    return (
      <div className="page-intro text">
        <div className="wrapper-main wrapper-content">
          <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
        </div>
      </div>
    );
  }
}

Text.propTypes = {
  text: PropTypes.string.isRequired
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class HeaderPage extends Component {
  render() {
    return (
      <div className="highlight-header" style={{ backgroundImage: `url(${this.props.image})` }}></div>
    );
  }
}

HeaderPage.propTypes = {
  image: PropTypes.string.isRequired
}

import React, { Component } from 'react';
import i18n from "i18next";

import Carousel from '../../components/Carousel';
import PageIntro from '../../components/PageIntro';
import BigHighlight from '../../components/BigHighlight';
import ListOptions from '../../components/ListOptions';
import Text from '../../components/Text';
import ListValues from '../../components/ListValues';

import imagePeople from '../../images/img-high-big-people.jpg';
import imageSustainability from '../../images/img-high-big-sustentability.jpg';
import imageProducts from '../../images/img-high-big-products.jpg';

import enDownload from '../../download/catalog.pdf';
import ptDownload from '../../download/catalogo.pdf';

const download = {
  'br': ptDownload,
  'en': enDownload
};

export default class Home extends Component {
  render() {
    return (
      <>
        <Carousel items={i18n.t('home.carrousel', { returnObjects: true })} />
        <PageIntro
          title={i18n.t('home.title')}
          description={i18n.t('home.description')}
        />
        <ListOptions options={i18n.t('home.options', { returnObjects: true })} />
        <Text text={i18n.t('home.text')} />
        <BigHighlight
          id={i18n.t('home.products.id')}
          title={i18n.t('home.products.title')}
          link={download[i18n.language]}
          button={i18n.t('home.products.button')}
          external={i18n.t('home.products.external')}
          image={imageProducts}
        />
        <BigHighlight
          id={i18n.t('home.sustainability.id')}
          title={i18n.t('home.sustainability.title')}
          description={i18n.t('home.sustainability.description')}
          link={i18n.t('home.sustainability.link')}
          button={i18n.t('home.sustainability.button')}
          external={i18n.t('home.sustainability.external')}
          image={imageSustainability}
        />
        <BigHighlight
          id={i18n.t('home.people.id')}
          title={i18n.t('home.people.title')}
          description={i18n.t('home.people.description')}
          link={i18n.t('home.people.link')}
          button={i18n.t('home.people.button')}
          external={i18n.t('home.people.external')}
          image={imagePeople}
        />
        <ListValues
          title={i18n.t('home.values.title')}
          list={i18n.t('home.values.list', { returnObjects: true })}
        />
      </>
    );
  }
}

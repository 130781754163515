import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class Place extends Component {
  render() {
    return (
      <div className="item-place">
        <h4>{this.props.name}</h4>
        {this.props.address.map((value, index) => (
          <p key={`address-${index}`} dangerouslySetInnerHTML={{ __html: value }} />
        ))}
      </div>
    );
  }
}

Place.propTypes = {
  name: PropTypes.string.isRequired,
  places: PropTypes.array
}
import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import i18n from "i18next";

// MODULOS
import Header from './modules/Header';
import Home from './modules/Home';
import About from './modules/About';
import People from './modules/People';
import Sustainability from './modules/Sustainability';
import Products from './modules/Products';
import Governance from './modules/Governance';
import Business from './modules/Business';
import Contact from './modules/Contact';
import Footer from './modules/Footer';
import NoMatch from './modules/NoMatch';
import DynamicScrollToTop from './components/DynamicScrollToTop';

// TRANSLATE
import translationEN from './locales/en/translation.json';
import translationBR from './locales/br/translation.json';

// translation catalog
const resources = {
  en: {
    translation: translationEN
  },
  br: {
    translation: translationBR
  }
};

// initialize i18next with catalog and language to use
i18n.init({
  resources
});

const App = () => {
  return (
    <Suspense fallback="loading">
      <Router>
        <DynamicScrollToTop />
        <Header />
        <Switch>
          <Route exact path={`(/|/br)`} component={Home} />
          <Route exact path={`(/grano|/br/a-grano)`} component={About} />
          <Route exact path={`(/our-people|/br/pessoas)`} component={People} />
          <Route exact path={`(/sustainability|/br/sustentabilidade)`} component={Sustainability} />
          <Route exact path={`(/products|/br/produtos)`} component={Products} />
          <Route exact path={`(/governance|/br/governanca)`} component={Governance} />
          <Route exact path={`(/businesses|/br/negocios)`} component={Business} />
          <Route exact path={`(/contact|/br/contato)`} component={Contact} />
          <Route exact path="*" component={NoMatch} />
        </Switch>
        <Footer />
      </Router>
    </Suspense>
  );
}

export default App;
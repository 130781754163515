import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Item extends Component {
  render() {
    return (
      <div className="item-business">
        <h3>{this.props.title}</h3>
        <div className="grid-business">
          <div className="image">
            <img src={this.props.image} alt={this.props.image} />
          </div>
          <div className="content" dangerouslySetInnerHTML={{ __html: this.props.description }} />
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}
import React, { Component } from 'react';
import PropTypes from 'prop-types'

export default class Option extends Component {
  render() {
    return (
      <div className="option">
        <i><img src={this.props.icon} alt={this.props.title} /></i>
        <h4>{this.props.title}</h4>
      </div>
    );
  }
}

Option.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}